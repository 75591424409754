body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden;

  font-family: Arial;
  font-size:16px;
}

html, body { height: 100% }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
	border:0;
	background-color:transparent;
}

.logged-in{
	display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.svg-container { 
	display: inline-block;
	position: relative;
	width: 100%;
	padding-bottom: 50%; 
	vertical-align: middle; 
	overflow: hidden; 
}

.svg-content { 
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}

#root{
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	overflow: hidden;
}

.click-catcher{
	z-index: -1; 
	position: fixed; 
	right: 0px; bottom: 0px; top: 0px; left: 0px; 
	background-color: transparent; 
	-webkit-tap-highlight-color: transparent;
}

.logout-button{
	color: white;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  margin-right:15px;
}

.logout-button:hover{
	background-color:rgba(255,255,255,0.1);
}


.login-blurb{
	font-size: 14px;
  font-weight: normal;
  margin-right: 20px;
}

.login-form{
	background-color:white;
	padding:10px 20px;
	max-width:100%;
	width:500px;
  margin: 0 auto;
  box-sizing: border-box;
}

.olio-login-error{
  padding: 0px 20px;
  max-width: 100%;
  width: 500px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  background-color: #ffcfcf;
  padding-left: 50px;
  border-radius: 5px;
  margin-top: -20px;
  position: relative;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  color: rgba(0,0,0,0.7);
  padding-left: 55px;
}

.olio-login-error-close{
	position:absolute;
	left:10px;
	top:50%;
	margin-top:-15px;
	border:2px solid rgba(0,0,0);
	font-size:20px;
	line-height:30px;
	border-radius:20px;
	width:30px;
	height:30px;
  background-image: url(cross.svg);
 	background-size: contain;
 	opacity:0.5;
}

.olio-login-error-close:hover{
	opacity:0.8;	
}

.login-form input{
	width: 100%;
  margin: 10px 0px;
  border: 1px solid rgb(220,220,220);
  padding: 10px 20px;
  box-sizing:border-box;
}

.login-form input[type=submit]{
	width:100%;
	margin:10px 0px;
  border: 1px solid rgb(220,220,220);
  padding: 10px 20px;
  background-color:#00a69c;
  color:white;
  padding:10px 20px;
  text-transform:uppercase;
  box-sizing:border-box;
}

.login-form input[type=submit]:hover{
  background-color:rgb(0,166,156,0.8);
}

.header{
  line-height: 30px;
  font-size: 16px;
  background-color:#00a69c;
  color:white;
  font-weight:bold;
  letter-spacing:2px;
  z-index:2;
}

.header .content{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  z-index:2;
}


.olio-logo{ 
  height: 70px;
  background-image: url(olio-logo2.png);
  width: 110px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 15px;
}

.nlab-logo{
	background-image: url(nlab-logo3.png);
	height: 70px;
	width: 120px;
	margin-left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;    
  margin-left: 30px;
}

.login-page{
	z-index:1000;
	position:absolute;
	left:0;right:0;top:0;bottom:0;
	background-color:#00a69c;

	display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-page .olio-logo{
  height: 140px;
  width: 220px;	
  margin:0px auto 20px;
  background-image: url(olio-logo.svg);
}

.header .content{
  /*padding: 0px 25px;	*/
}

.menu-bar{
	background-color:rgb(240,240,240);
	background-color:white;
}

.menu-bar .content{
	/*padding: 0px 10px;*/
	overflow-x: auto;
	flex-basis:50px;
	display: flex;
	flex-direction: row;
}

.menu-bar .content::-webkit-scrollbar{
	display:none;
}

.tab{
  position: relative;
  font-size: 14px;
  margin-right: 20px;
  padding: 15px 15px 20px;
  cursor:pointer;
  user-select: none;
  white-space: nowrap;
}

.tab:hover{
	color:#888;
}

.tab.tab-selected:hover{
	color:#000;	
}

.foodbank{
	padding:0px 25px;
}

h1{
	font-size:19px;
	letter-spacing:1px;
	font-weight:bold;
	margin:30px 0px 30px;
	text-transform:uppercase;
}

h2{
	font-size:19px;
	font-weight:bold;
	margin:20px 0px 0px;
}

h3{
	font-size: 16px;
  margin: 15px 0px 0px;
  font-weight: bold;
}

.menu-tab-underline{
	position:absolute;
	bottom:10px;
	left:15px;
	right:15px;
	height:4px;
	background-color: #00a69c;
	will-change: transform;
}

.main-area{
	flex-grow:1;
	position:relative;
}

.layer-panel{
	position:absolute;
	left:0px;
	width:320px;
	top:10px;
	bottom:10px;
	padding: 15px;
	background-color: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.5);
  box-sizing: border-box;
}

.layer-panel.hidden{
	left:-320px;
}


.layer-group{
  display: flex;
  flex-direction: column;
  margin-top:10px;
}

.layer-group .layer-control{
	margin-left:20px;
}
.layer-group .layer-control:first-child{
	margin-left:0px;
	font-weight:bold;
}



.data-layer h3{
  margin-bottom: 10px;
  margin-top: 23px;
}


.data-layer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
	overflow-x:hidden;
  overflow-y: auto;
}

.insight-content{
	margin-left:12px;
}

.selection-panel{
	height: 100%;
	overflow-x:hidden;
	overflow-y:auto;
  position: relative;
}

.data-layer .blurb{
    margin: 10px 0px 0px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
    margin-top: 0;
    font-size: 13px;
}

.tipping-point-graph{
	margin-top:10px;
	display:flex;
	flex-direction:row;
}

.tipping-point-graph > div{
	flex-grow:1;
	flex-basis: 100px;
	height:34px;
	line-height:17px;
	text-align:center;
	font-size:15px;
	padding:8px 0px;
	font-weight:bold;
}

.tipping-point-blurb{
	font-size:14px;
	line-height:17px;
	margin-top:10px;
}

.tipping-point-good{
	background-color:#7ed776;
}

.tipping-point-medium{
	background-color:#da812f;
}

.tipping-point-bad{
	background-color:#e42211;
}

.tipping-point-arrow-container{
	margin-left:6%;
	margin-right:6%;
}

.tipping-point-arrow{
	  width: 0;
    height: 0;

    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #000;
    left:0%;
    position:relative;
    margin-left:-8px;
    will-change: transform;
}


.simple-scale{
	height:30px;
	
	border:1px solid rgba(230,230,230);
	background: linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,0,0,0.5));

	margin-top:10px;
}

.data-panel{
	position:absolute;
	right:0px;
	width:315px;
	top:10px;
	bottom:10px;
	padding:15px;

	background-color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.5);
  box-sizing:border-box;
}

.data-panel.hidden{
	right:-315px;
}


.fid-stat{
	font-size: 32px;
}
.fid-stat div{
	margin-left:0.2em;
}


.ww-grid{
	border:1px solid rgb(220,220,220);
	border-radius:10px;
	overflow: hidden;
}

.ww-arrow{
	margin-left:0.2em;
	/*font-size:0.8em;*/
	font-size:20px;
}

.ww-top-arrow{
	margin-left:0.3em;
	font-size:20px;
	position:relative;
	top:4px;
}

.ww-top{

	text-align:center;
	line-height:36px;
	font-size:13px;
	font-weight:bold;
	text-transform:uppercase;

	background-color:rgba(255,255,255,0.5);
}

.ww-top div{
	/*
	font-size: 25px;
	margin-left: 0.2em;
	*/
}

.ww-stat{
	display:flex;
	flex-direction:row;
	font-size:20px;
	text-align:center;
	background-color:white;
}

.ww-stat > div{
	flex-grow:1;
	border-left:1px solid rgb(220,220,220);
	padding: 5px;
}

.ww-stat > div:first-child{
	border-left:0;
}

.ww-text{
	text-align:center;
	font-size:12px;
	margin:5px 0px;
}


.stat-up, .stat-down, .stat-same{
	width:0.8em;
	height:0.8em;
	position:relative;
	top:-0.1em;
	background-size: contain;
	display:inline-block;
	vertical-align:middle;
}

.stat-up{
	background-image:url(arrow-up.svg);
}

.stat-down{
	background-image:url(arrow-down.svg);
}

.stat-same{
	background-image:url(arrow-same.svg);
}

/*
.stat-up, .stat-down{
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	position: relative;
	display: inline-block;
	vertical-align: middle;
	color: #F00;
	box-sizing: border-box;
	width: 0;
	height: 0;
	border-style: solid;
	border-bottom-color: transparent;
	border-left-color: transparent;

	border-width: 0.3em;

	margin-top: 0.2em;
  margin-bottom: 0.5em;
  margin-left: 0.2em;

}

.stat-down{
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	color:green;
}

.stat-up:before, .stat-down:before{
		content: "";
    box-sizing: border-box;
    right: 0;
    position: absolute;
    box-shadow: inset 0 0 0 3.2em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: right top;
    transform-origin: right top;

    top: -0.2em;
    height: 0.3em;
    width: 0.6em;
}

.stat-up:after, .stat-down:after{
    content: "";
    box-sizing: border-box;
}


.stat-same{
	background-color:rgb(128,128,128);
  display:inline-block;
  vertical-align: middle;
  
	width:17px;
	height:6px;
  margin: 10px;
}

*/

.data-panel .population{
  font-size: 15px;
  margin: 10px 0px 0px;
  margin-top:50px;
}

.data-panel .population .value{
	float:right;
}
.data-panel .deprivation-title{
	margin-top: 10px;
  font-weight: bold;
  font-size: 15px;
}




@media only screen and (max-width: 600px) {
  .layer-panel{ 	width:240px; }
	.layer-panel.hidden{		left:-270px;	}
	.data-panel{		width:240px;	}  
	.data-panel.hidden{		right:-270px;	}  
}


.appear-button{
	font-size:10px;
	text-align:center;
	line-height:20px;
	text-transform:uppercase;
	font-weight:bold;
	position:absolute;
	top: 25px;
  height: 60px;
  width: 50px;
  background-color: white;
  line-height: 25px;
  display: flex;
  flex-direction: column-reverse;
  cursor:pointer;
  user-select: none;
}


.hasHover .appear-button:hover .arrow-side{
	border-left-color:#888;
}
.hasHover .appear-button:hover{
	color:#888;
}

.content{
	margin: 0 auto;
	width: 1280px;
	max-width: 100%;
}

.arrow-side{
	width: 0; 
  height: 0; 
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #000;
  margin: 0px auto;
  will-change: transform;
}

.arrow-right{
	transform: rotate(180deg);
}


.arrow-vertical
{
	display:inline-block;
	width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
  position:relative;
  top:-2px;
  margin-left:8px;
}

.arrow-down
{

}

.layer-panel .appear-button{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 2px 1px 2px rgba(0,0,0,0.4);
}

.data-panel .appear-button{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: -2px 1px 2px rgba(0,0,0,0.4);
}

.data-panel .appear-button{
	right:100%;
}

.layer-panel .appear-button{
	left:100%;
}

.page{
	position:absolute;
	left:0px;
	right:0px;
	top:0px;
	bottom:0px;
	overflow:hidden;
	overflow-y:auto;
	/*visibility:hidden;*/
}

.foodbank-dialog
{
	position: fixed;
  background-color: white;
  width: 340px;
  height: 400px;
  border: 1px solid black;
  left: 50%;
  top: 50%;
  margin-left: -170px;
  margin-top: -200px;
  padding: 10px;
  box-sizing:border-box;
}
/*
.page-current{
	
	visibility:visible;
}
*/

.map{
	position:absolute;
	left:0px;
	right:0px;
	top:0px;
	bottom:0px;
	background-color:orange;
}

.mapboxgl-canvas{
	outline: 0;
}

.map-container{
	position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	background-color:#eee8e1;
}

/*
.mapboxgl-popup-content, .mapboxgl-popup-tip, .mapboxgl-popup{
	pointer-events:none;
}
*/

.mask{
	opacity:0.8;
}

.layer-control
{
	display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:flex-start;
}

.layer-button{
  border: 1px solid rgb(220,220,220);
  padding: 1px 10px;
  font-size: 14px;
  margin: 4px 0px;
  border-radius: 6px;
  line-height: 20px;
  margin-right: auto;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 3px 0px;
  margin-right: 8px;
  line-height: 20px;
}

.info-button{
	position:relative;
	display:inline-block;
	width:15px;
	line-height:10px;
	height:15px;
	text-align:center;
	border:1px solid #00a69c;
	color:#00a69c;
	border-radius:15px;
	font-size:15px;
  font-style: italic;
  padding: 0;
  font-family:Times New Roman;
}

.info-button:hover{
  background-color: #00a69c1a;
}

.layer-timeline-badge{
	display:inline-block;
	width:15px;
	height:15px;
	line-height:15px;
	text-align:center;
	border:1px solid #aaa;
	color:#aaa;
	border-radius:15px;
	padding:0;
	font-size:14px;
  font-style: italic;
	margin-left:8px;
}

.layer-timeline-badge:hover{
	background-color:#F4F4F4;
}

.foodbank-title-area{
	display:flex;
	flex-direction:row;
	align-items: center;
	margin-bottom: 0px;
}

.foodbank-title-area h1{
	margin: 30px 0px 10px;
  margin-right: 40px;
}

.foodbank-filters{
	margin:10px 0px;
	border:1px solid rgb(220,220,220);
	border-radius:15px;
	display:inline-block;
	overflow:hidden;
	height:30px;
}

.foodbank-filters > button{
	border-left:1px solid rgb(220,220,220);
	display:inline-block;
	padding:0px 15px;
	margin:0px;
  height: 30px;
  line-height: 30px;
  font-size:12px;
  text-transform:uppercase;
}

.foodbank-filters > button.selected{
	background-color:#00a69c;
	color:white;
}



.foodbank-filters > div:first-child{
	border-left:0;
}

.map-container .rocker{
}

.map-timeline-switch{
	z-index: 1;
  position: absolute;
  bottom: 7px;
  left: 50%;
  margin-left: -139px;	
}

.map-timeline-date{
	position:absolute;
	top:47px;
	height:20px;
	left:0px;
	right:30px;
	text-align:center;
	font-size:14px;
}

.map-layer-name{
	z-index:1;
	position:absolute;
	top:10px;
	left:0px;
	right:0px;
	text-align: center;
}

.map-layer-name div{
	margin: 0 auto;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 6px;
  font-size: 17px;
  background-color: rgba(0, 166, 156, 1);
  color: white;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
}

.info-tooltip{
	max-width:300px;
	font-size:15px;
	line-height:20px;
	z-index:100;
	
	padding:10px 15px !important;
	box-shadow: 0 2px 3px rgb(0,0,0,0.3);
	border:0 !important;

	border-radius: 5px !important;
	overflow: hidden;
}

.info-tooltip.show{
	opacity: 1 !important;
}

.tooltip-title{
	font-weight:normal;
	margin-bottom:8px;
	font-size:15px;

	background-color:#00a69c;
	color:white;
	margin:-10px -15px 8px;
	padding:8px 15px;
}

.tooltip-single{
	font-weight:bold;
	font-size:15px;
}

.tooltip-item{
	display: flex; 
	flex-direction: row; 
	margin-top: 15px; 
	margin-bottom: 10px;
	/*justify-content: center;*/
	align-items:center;
}

.tooltip-item-more{
	font-weight:bold;
	margin-top:5px;
	text-align:left;
	margin-left:35px;
}

.tooltip-item .outlet-icon{

}

ul{
	list-style-type:disc;
	list-style-position: inside;
}

/*
.reactour__helper button{
	right:15px;
	top:15px;
}
*/
.reactour__helper{
	padding-right: 45px !important;
}

.layer-button-hovered{
}

.layer-button:hover{
	background-color:#FBFBFB;
}


.layer-button-current{
	background-color:#00a69c !important;
  border-color: #00a69c;
	color:white;
}


.rocker{
  border-radius: 15px;
  overflow:hidden;
  border:1px solid #ccc;
  border: 1px solid rgba(130,130,130,0.16);
  display:inline-block;
  z-index: 0;
}

.rocker button{
	display:inline-block;
	/*min-width:90px;*/
	padding:0px;
	height:24px;
	line-height:24px;
	font-size:12px;
	text-transform:uppercase;
	text-align:center;
	position:relative;
}


.rocker-content{ 
	padding:0px 14px;
	z-index: 2;
	/*border-left:1px solid #ccc;*/
}

.rocker-background{
	position:absolute;
	left:0px;
	right:0px;
	bottom:0px;
	top:0px;
	z-index:-2;
	border-left:1px solid #ccc;
	border-left: 1px solid rgba(130,130,130,0.16);
	background-color:#FFF;
}

.rocker-selected-background{
	position:absolute;
	left:0px;
	right:0px;
	top:0px;
	bottom:0px;
	background-color:#00a69c;
	z-index: -1;
}


.rocker button:first-child .rocker-background{
	border-left:0px;
}

.rocker button:hover .rocker-background{
	background-color:#f9f9f9;
	/*background-color:#ecf9f8;*/
}

.rocker-selected{
	/*background-color:#00a69c !important;*/
	background-color:transparent !important;
	color:white;
}

.data-view-top{
	display:flex;
	flex-direction:row;
  align-items: center;
  margin-bottom:10px;
}

.data-view-top h1{
	margin:30px 0px;
	margin-right:40px;
}


.grid{
	display:flex;
	flex-direction: column;
	overflow-x: auto;
	margin-bottom: 20px;
}

.row{
	flex-basis:32px;
	display:flex;
	flex-direction:row;
  align-items: center;
  align-items: stretch;
}

.row .cell{
	border-top:1px solid #DDD;
}

.row-header{
	background-color:white;
	font-weight: bold;
  font-size: 13px;
  position: sticky;
  top: 0px;
  z-index:1;
}

.row-header .cell{
	border-top:0;
	border-bottom:2px solid #DDD;	
	line-height: 20px;
}

.row > span{
	flex-basis:110px;
	display:flex;

	flex-direction: column;
	justify-content: center;
	text-align: right;
}

.row-header span{
	text-align:center;

  cursor:pointer;
  user-select:none;
}

.cell-red, .cell-amber, .cell-green{
	height: 20px;
    width: 20px;
    border-radius: 20px;
    display: inline-block;
    border: 2px solid white;
    box-shadow: 0px 0px 6px rgba(0,0,0, 0.2);
    align-self: center;
}

.cell-red{
	background-color:#ff0000;
}

.cell-amber{
	background-color:#ffa500;
}

.cell-green{
	background-color:#5be000;
}



.row-header .cell{
	position:relative;
}

.hasHover .row-header .cell:hover{
	/*background-color:#F8F8F8;*/
	color:#888;
}
.hasHover .row-header .cell:hover .sort-arrow{
	border-color:#888;
}

.sort-arrow{
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

  position: absolute;
  right: 8px;

  top: 50%;
  margin-top: -6px;

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.sort-arrow-descending{
	margin-top:-2px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);	
}

.column-button-container{
	position:relative;
	margin-left: auto;
}

.column-button{
	cursor:pointer;
  user-select:none;
  text-transform:uppercase;
  font-size:13px;
  font-weight:bold;
  line-height: 30px;
  padding:0px 10px;
}

.column-button-menu{

	width:240px;
	position:absolute;
	top:100%;
	margin-top:5px;
	right:0px;
	background-color:white;
	box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
	padding:15px;
	background-color:white;
	z-index: 2;
}

.hasHover .column-button:hover{
	color:#888;
}

.hasHover .column-button:hover .arrow-vertical{
	border-top-color:#888;
}

.column-button-separator{
	width: 100%;
  border-bottom: 1px solid rgb(230,230,230);
  margin: 8px 0px;
}

.column-button-container .blurb{
	font-size:15px;
	padding-bottom:10px;
	margin-bottom:10px;
	border-bottom:2px solid #DDD;
}

.column-button-item{
	display:flex;
	flex-direction:row;
	line-height:24px;
	font-size:15px;
  align-items: center;
}
.column-button-item span{
	flex-grow:1;
}

.row .cell-header{
  text-align: left;
  line-height: 21px;
  font-size: 15px;
}

.row-header .cell{
	display:block;
}

.row-header .cell-header{
	text-align:left;
}

.cell-header{
	border-right:2px solid #DDD;
  background-color:white;
}

.cell{
	padding-right: 20px;
  flex-grow: 0;
  flex-shrink: 0;
}

.cell .info-button{
	margin-left:5px;
}


.export{
	padding:0px 25px;
}

.export .blurb{
	font-size:15px;
	margin:15px 0px 25px;
	line-height: 19px;
}

.export .file-download-button{
	background-color:#00a69c;
	padding:0px 20px;
	line-height:30px;
	color:white;
	border-radius:5px;
	font-size:15px;
}

.export h2{
	margin-top:40px;
	margin-bottom:15px;
}

.data{
	padding:0px 25px;
}

.foodbank-list-container
{
  /*position: absolute;*/
  width: 100%;
}

.foodbank h2{
	margin-top:40px;
	margin-bottom:20px;
}

.foodbank-footer{
	margin-top:0px;
	/*font-size:15px;*/
}

.foodbank-footer a{
	color:#00a69c;
}

.foodbank-list{
	display:flex;
	flex-wrap:wrap;
	flex-direction:row;
	justify-content: space-between;
	gap: 30px;
}

.foodbank-panel, .foodbank-panel-spacer{
	flex-basis: 250px;
	flex-grow:1;
}

.foodbank-panel{
  border: 1px solid black;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
  cursor:pointer;
  user-select:none;
}


.foodbank-panel-spacer{
	height:0px;
}

.foodbank-panel-add{
	display:flex;
	justify-content:space-around;
	flex-direction: column;
  text-align: center;
  font-size:150px;
  color:#CCC;
  min-height:300px;
}


.foodbank-panel .details{
	height:180px;
	overflow:hidden;
}

.foodbank-panel .name{
	font-size:19px;
	font-weight:bold;
	line-height:23px;
	margin-bottom:10px;
}

.foodbank-panel .organisation{
	font-size:18px;
	line-height:22px;
	margin-bottom:8px;
}

.foodbank-panel .address{
	font-size:14px;
	line-height:17px;
	margin-bottom:10px;
	white-space: pre-wrap;
}


.foodbank-map{
	width:100%;
  background-color: #e6e4e0;
}

.outlet-icon{
	width: 30px;
  height: 30px;
  display: inline-block;
  background-size: contain;
  margin: -6px 0px;
  margin-right: 5px;
  overflow:hidden;
  flex-shrink: 0;
}

.store-icon{
	background-image: url(store.png); 
}
.bank-icon{
	background-image: url(bank.png);
}
.restaurant-icon{
	background-image: url(restaurant.png);
}
.school-icon{
	background-image: url(school.png);
}
.ward-icon{
	background-color: rgb(236 236 236);
	/*
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	*/
	line-height: 26px;
	font-size: 14px;
	box-sizing: border-box;
	padding-left: 7px;
	text-shadow: 0 0 5px white;
	height:26px;
	border:1px solid rgba(0,0,0,0.1);
	/*border-right:none;*/
	border-radius:10px;

}
.ward-icon:before{
	content:'Wa';
}
.ward-welfare-icon{
	border-radius:15px;
	background-color:#ff4444;
	border:3px solid white;
	box-sizing: border-box;
	box-shadow:0 0 4px rgba(0,0,0,0.5);	
	height:20px;
	width:20px;
	margin:0px 10px 0px 5px;
}

.definitions-page{
	margin:20px;
  max-width: 600px;
  line-height:20px;
  font-size:15px;
}

.definitions-page ul{
	list-style-position: outside;
	margin-left:25px;
}
.definitions-page ul li{
	margin-top:5px;
}

.definitions-page a{
	text-decoration:none;
	border-bottom:1px solid #00a69c;
	color:#00a69c;
}
.definitions-page p{
	margin:10px 0px;
}
.definitions-page h2{
	margin:30px 0px 20px;
}
.definitions-page h3{
	font-size:15px;
	margin-bottom:4px;
}

.definitions-page .meta{
	margin-left:15px;
}

.definitions-page b{
	font-weight:bold;
}

.layer-outlets{
	width:100%;
	margin-top: 4px;
	margin-top:auto;
}

.layer-outlets-item{
	display:flex;

	flex-direction:row;
	margin-top:15px;
	margin-bottom:10px;
	align-items: center;
}

.layer-outlets-name{
	/*flex-basis:100px;*/
	font-weight:normal;
	font-size:14px;
	margin-right:8px;
}

.layer-outlets input{
  margin-left: auto;
  margin-right: 30px;
}




/* TEST */

.layer-parent  {
	z-index:0;
	text-align:left;
	border:1px solid rgb(220,220,220);
	box-sizing:border-box;
	/*
	margin:10px 0px;
	padding:5px 10px;
	*/
	overflow:hidden;
	/*height:38px;*/
	border-radius:8px;
	display: flex;
  flex-direction: column;

  border:0;
  padding:0;
  border:1px solid rgb(220,220,220);

  padding:0px 10px;
  margin:8px 1px;
  align-self: stretch;
  align-items: flex-start;
}

.layer-child-main{
	border:1px solid rgba(0,0,0,0) !important;
}

.layer-open .layer-child-main{
	border:1px solid rgb(220,220,220) !important;
}

.layer-child.layer-current{
	background-color:#00a69c;
	color:white;
}

.layer-parent-items{
	margin:5px 0px;
}

.layer-parent .rocker{
	margin-top:15px;
	margin-bottom:5px;
}

.layer-parent-content{
}

.layer-open{
	/*height:auto;*/
}

.layer-parent-title{
	margin:5px 0px;
	line-height:35px;
}

.layer-child {
	border:1px solid rgb(220,220,220);
	box-sizing:border-box;
	margin:3px 0px;
	padding:5px 10px;
	border-radius:8px;
	font-size:15px;

}

.map-title-block{
	position: absolute;
  top: 10px;
  margin: 0px auto;
  background-color: rgba(255,255,255,1);
  width: 340px;
  height: 78px;
  left: 50%;
  margin-left: -170px;
  border-radius:10px;
  box-shadow:0 0 4px rgba(0,0,0,0.5);
  overflow:hidden;
  top: 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid rgb(240,240,240);

}

.map-title-block .title{
	font-weight: bold;
  text-align: center;
  font-size: 16px;
  margin-top: 11px;
  line-height: 22px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 50px;
}

.download-button{
  font-size: 14px;
  line-height: 30px;
  padding: 0px 0px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: inline-block;

  background-image: url(screenshot.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 30px;
  background-size: 30px 30px;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);

  overflow:hidden;
  color:transparent;
}

.map-download-button{
	position: absolute;
  top:8px;
  left: 50%;
  margin-left:120px;
  z-index: 1;
}

.map-download-button:hover{
	background-color:rgb(230,230,230);
}

.mapboxgl-ctrl-top-left{
	left:50%;
	margin-left:168px;
	top:-1px;
}

/*
.mapboxgl-ctrl-group button{
	display:inline-block;
	vertical-align: middle;
	height:30px;
	width:50px;
}

.mapboxgl-ctrl-bottom-left{
	bottom:-30px;
}

.mapboxgl-ctrl-group button+button {
  border-top: 0;
  border-left: 1px solid #ddd;
}*/

.mapboxgl-ctrl-bottom-left{
	bottom:-30px;
}

.mapboxgl-ctrl-bottom-right{
	bottom:-30px;
}


.map-legend{
	position:absolute;
	bottom:0px;
	width:330px;
	left:50%;
	margin-left:-165px;
	background-color:white;
	border-radius:10px;
	box-shadow:0 0 4px rgba(0,0,0,0.5);
	overflow:hidden;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.map-legend-bar{
  /*height: 20px;*/
  display: flex;
  flex-direction: row;
	border:1px solid rgb(230,230,230);
}


.map-legend-values{
	display: flex;
  flex-direction: row;
  line-height: 20px;
  font-size: 14px;
  
  font-weight:bold;
}

.map-legend .map-legend-bar{
	margin:8px 10px 5px;
}

.map-legend .map-legend-values{
	padding: 0 7px;
}

.map-legend-bar div{
  height: 13px;
	flex-grow:1;
}


.map-legend-text{
	font-weight:normal;
	margin-left:5px;
	margin-right:5px;
	margin-bottom:5px;
	color:rgb(140,140,140);
	font-size: 14px;
}

.ww-dots{
	height:20px;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	align-items: center;
	margin-top:20px;
}

.ww-value{
	font-size:22px;
	line-height: 30px;
}
.ww-value div{
  /*margin-left: 0.2em;*/
}

.ww-dots button{
	height:16px;
	width:16px;
	border-radius:10px;
	padding:0;
	border:2px solid #FFF;
}

.ww-dots .green{	background-color:green;}
.ww-dots .amber{	background-color:orange;}
.ww-dots .red{	background-color:red;}

.ww-dots button.hover{
	opacity:0.5;
}

.ww-dots .current{	width:20px; height:20px; border:2px solid #666; opacity:1 !important;}

.selection-panel h3{
	margin-bottom:15px;
	margin-top:20px;
}

.ww-rank{
  margin-top: 10px;
  font-size: 15px;
}

.ww-blurb{
	margin-top: 10px;
	font-size: 13px;
	line-height: 16px;
  color: rgb(100,100,100);
  height: 48px;
}

.chart-view{
	padding: 0px 25px;
}

.chart-view h2 .info-button{
	margin-left:10px;
}	

.chart-view h2{
	position:relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 35px;
  max-width:350px;
}

.chart-view .download-button{
	margin-left: auto;
}

#layer-tip b{
	font-weight:bold;
}

#layer-tip .cell-red{
	height: 15px;
  width: 15px;
}

.clippy{
	right: 100px !important;
  bottom: 50px !important;
  top: auto !important;
  left: auto !important;
}
.clippy-balloon{
	right: 100px !important;
	bottom: 160px !important;
	top: auto !important;
	left: auto !important;	
}

/*
	height: 20px;
    width: 20px;
    border-radius: 20px;
    display: inline-block;
    border: 2px solid white;
    box-shadow: 0px 0px 6px rgba(0,0,0, 0.2);
    align-self: center;
}

.cell-red{
	background-color:#ff0000;
}

*/


.food-outlet-list-item{
	margin:10px 0px;
}

.food-outlet-list-item b{
	font-weight:bold;
}